<template>
  <div>
    <div class="row page-titles mx-0">
      <div class="col-md-6 p-md-0">
        <div class="welcome-text">
          <h4>Levels</h4>
        </div>
      </div>
      <div class="col-md-6 p-md-0 justify-content-md-end mt-2 mt-md-0 d-flex">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="/">Home</a></li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Dashboard-Home' }"
              >Dashboard</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'Levels' }">Levels</router-link>
          </li>
          <li class="breadcrumb-item active">Edit</li>
        </ol>
      </div>
    </div>

    <div class="row tab-content">
      <div id="" class="tab-pane fade active show col-md-9">
        <div class="card top-red-border">
          <div class="card-header">
            <h4 class="card-title">Edit Level Info</h4>
          </div>
          <div class="card-body">
            <!-- FeedBack -->
            <feedback-messages :msgs="msgs" :errors="errors" />
            <!-- /FeedBack -->
            <div class="basic-form">
              <form id="" @submit.prevent="submitFormData()">
                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Name <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="text"
                      name="firstname"
                      required
                      class="form-control"
                      v-model="level.value"
                      placeholder="Name"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Order <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <input
                      type="number"
                      name="level_order"
                      required
                      class="form-control"
                      v-model="level.level_order"
                      placeholder="Level Order"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-md-3 col-form-label"
                    >Classes <span style="color: red">*</span></label
                  >
                  <div class="col-md-9">
                    <div
                      class="form-group row"
                      v-for="(single_class, index) in level.classes"
                      :key="index"
                    >
                      <div class="col-md-5">
                        <input
                          type="text"
                          name="class_name"
                          required
                          class="form-control"
                          v-model="single_class.name"
                          placeholder="Name"
                        />
                      </div>
                      <div class="col-md-5">
                        <input
                          type="number"
                          name="capacity"
                          required
                          class="form-control"
                          v-model="single_class.capacity"
                          placeholder="Capacity"
                        />
                      </div>
                      <div class="col-md-2" v-if="index != 0">
                        <a
                          @click.prevent="removeClassBlock(index)"
                          href="#"
                          class="btn btn-danger"
                          title="Rmove"
                          ><i class="la la-times"></i> </a
                        ><br />
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-md-4">
                        <a
                          @click.prevent="addAnotherClassBlock"
                          href="#"
                          class="btn btn-secondary-original"
                          onclick="this.blur();"
                          ><i class="la la-plus"></i> Another Class</a
                        ><br />
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="form-group row"
                  style="padding-top: 2rem; margin-bottom: 0px"
                >
                  <div class="col-md-12">
                    <button
                      :disabled="disableBtn"
                      name="submit"
                      type="submit"
                      class="btn btn-primary"
                      value="Submit"
                      onclick="this.blur();"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FeedbackMessages from "@/components/FeedbackMessages.vue";
import useLevels from "@/composables/levels";
import { ref, inject, onBeforeMount } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
  components: {
    FeedbackMessages,
  },
  setup(props) {
    const disableBtn = ref(false);
    const swal = inject("$swal");
    const hasPermission = inject("hasPermission");
    const router = useRouter();

    const { getLevel, updateLevel, level, msgs, errors } = useLevels();

    onBeforeMount(async () => {
      //first: check the user Permission
      if (!hasPermission("edit_levels"))
        router.push({
          name: "401",
        });

      await getLevel(props.id);
    });

    const addAnotherClassBlock = () => {
      level.value.classes.push({
        id: null,
        name: "",
        capacity: "",
      });
    };

    const removeClassBlock = (index) => {
      level.value.classes.splice(index, 1);
    };

    const submitFormData = async () => {
      swal.fire({
        title: "Please wait while submit your request",
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading();
        },
      });

      errors.value = [];
      disableBtn.value = true;

      await updateLevel(level, props.id).then((resp) => {
        swal.close();
        if (resp != "fail") {
          router.push({
            name: "Levels",
            params: {
              id: resp,
            },
          });
        }
        window.scrollTo(0, 0);
        disableBtn.value = false;
      });
    };

    return {
      submitFormData,
      addAnotherClassBlock,
      removeClassBlock,
      level,
      disableBtn,
      msgs,
      errors,
    };
  },
};
</script>
