import {
    ref,
    reactive
} from 'vue';
import axios from '@/axios';
import store from '@/store';

export default function useLevels() {
    const errors = ref([]); //ref: to make it reactive
    const msgs = ref([]);
    const level = ref({});
    const levels = ref([]);
    const levels_order = ref([]);
    const lists = reactive({});


    const getLevels = async () => {
        try {
            let response = await axios.get('/api/levels');
            levels.value = response.data.data.levels;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const getLevel = async (id) => {
        try {
            let response = await axios.get('/api/levels/' + id);
            level.value = response.data.data.level;
        } catch (e) {
            await store.dispatch("handleException", e);
        }

    }

    const createLevel = async () => {
        errors.value = ''; //
        try {
            let response = await axios.get('/api/levels/create');
            levels_order.value = response.data.data.levels_order;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }

    const storeLevel = async (data) => {
        errors.value = ''; //
        try {
            let response = await axios.post('/api/levels/', data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }


    const deleteLevel = async(id) => {
        try {
            await axios.delete('/api/levels/' + id);
        } catch (e) {
            await store.dispatch("handleException", e);

        }
    } 


    const updateLevel = async(data, id) => {
        errors.value = ''; //
        try {
            data.value._method = 'PATCH';
            let response = await axios.post('/api/levels/' + id, data._rawValue);
            return response.data.data;
        } catch (e) {
            errors.value = await store.dispatch("handleException", e);
            return 'fail';
        }
    }
    

    return {
        level,
        levels,
        lists,
        msgs,
        errors,
        levels_order,
        getLevels,
        createLevel,
        storeLevel,
        getLevel,
        deleteLevel,
        updateLevel,
    }
}